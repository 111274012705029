import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const sliders = document.querySelectorAll('[data-js-module="slider"]');

  const { default: Slider } = await import(
    /* webpackChunkName: "slider" */ './slider'
  );

  forEach(sliders, (index, element) => new Slider(element));
}
