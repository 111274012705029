import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const lightboxConfigs = document.querySelectorAll(
    '[data-js-lightbox-config]'
  );
  if (lightboxConfigs) {
    const { default: Lightbox } = await import(
      /* webpackChunkName: "lightbox" */ './lightbox'
    );

    forEach(lightboxConfigs, (_, element) => {
      new Lightbox(element);
    });
  }
}
