import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="header"]');

  const { default: Header } = await import(
    /* webpackChunkName: "header" */ './header'
  );

  forEach(elements, (index, element) => new Header(element));
}
