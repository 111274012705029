import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const socialEmbeds = document.querySelectorAll(
    '[data-js-module="social-embed"]'
  );

  const initSocialEmbeds = async () => {
    const { default: SocialEmbed } = await import(
      /* webpackChunkName: "social-embed" */
      './social-embed'
    );

    forEach(socialEmbeds, (index, element) => new SocialEmbed(element));
  };

  if (socialEmbeds.length) initSocialEmbeds();
}
