import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('.share-link--copy');

  const { default: ShareLink } = await import(
    /* webpackChunkName: "share-link" */ './share-link'
  );

  forEach(elements, (index, element) => new ShareLink(element));
}
