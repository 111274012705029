import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="main-stage"]');

  const { default: MainStage } = await import(
    /* webpackChunkName: "main-stage" */ './main-stage'
  );

  forEach(elements, (index, element) => new MainStage(element));
}

init();
