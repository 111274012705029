import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="map"]');

  if (elements.length) {
    const { default: Map } = await import(
      /* webpackChunkName: "map" */ './map'
    );

    forEach(elements, (index, element) => {
      if (element.dataset.jsThirdPartyContent) return;
      new Map(element);
    });
  }
}
