import forEach from '../../../javascripts/dom/forEach';

const init = async () => {
  const $polls = document.querySelectorAll('[data-js-module="poll"]');

  const { default: Poll } = await import(
    /* webpackChunkName: "poll" */ './poll'
  );

  forEach($polls, (index, element) => new Poll(element));
};

export default init;
