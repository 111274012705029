import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const cardsStack = document.querySelectorAll(
    '[data-js-module="cards-stack"]'
  );

  const { default: CardsStack } = await import(
    /* webpackChunkName: "cards-stack" */ './cards-stack'
  );

  forEach(cardsStack, (index, element) => new CardsStack(element));
}
