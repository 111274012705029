import '../stylesheets/main.scss';

// Use what-input to track input method
import 'what-input';
import smoothscroll from 'smoothscroll-polyfill';

import domready from 'domready';

// Shims
import forEach from './dom/forEach';

import initImage from '../components/image/javascripts';
import '../components/icon/javascripts';

import initSocialEmbed from '../components/social-embed/javascripts';

import initAccordion from '../components/accordion/javascripts';
import initDialog from '../components/dialog/javascripts';
import initTabs from '../components/tabs/javascripts';
import initCardsStack from '../components/cards-stack/javascripts';
import initSlider from '../components/slider/javascripts';
import initVideo from '../components/video/javascripts';
import initLightbox from '../components/lightbox/javascripts';
import initContactSidebar from '../components/contact-sidebar/javascripts';
import initMainStage from '../components/main-stage/javascripts';
import initVideoStage from '../components/video-stage/javascripts';
import initMap from '../components/map/javascripts';
import initPoll from '../components/poll/javascripts';
import initHeader from '../components/header/javascripts';
import initShareLink from '../components/share-link/javascripts';
import initThirdPartyContent from '../components/third-party-content/javascripts';

smoothscroll.polyfill();

if (process.env.STORYBOOK) {
  // eslint-disable-next-line
  const { worker } = require('../mocks/browser');
  worker.start();
}

function enhancedDomReady(init) {
  domready(init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  initImage();
  initHeader();
  initContactSidebar();
  initMainStage();
  initVideoStage();
  initCardsStack();
  initSlider();
  initSocialEmbed();
  initMap();
  initPoll();
  initAccordion();
  initDialog();
  initTabs();
  initVideo();
  initLightbox();
  initShareLink();
  initThirdPartyContent();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
});
