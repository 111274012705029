import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="video-stage"]');

  const { default: VideoStage } = await import(
    /* webpackChunkName: "video-stage" */ './video-stage'
  );

  forEach(elements, (index, element) => new VideoStage(element));
}

init();
