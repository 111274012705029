import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="contact-sidebar"]'
  );

  const { default: ContactSidebar } = await import(
    /* webpackChunkName: "contact-sidebar" */ './contact-sidebar'
  );

  forEach(elements, (index, element) => new ContactSidebar(element));
}
